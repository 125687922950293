<template>
  <div class="your-quotes-content-grid">
    <div>
      <AppAlert
        v-if="showSuccessfulSummaryAlert || showActionNeededSummaryAlert"
        class="summary-alert"
        custom-icon="fa-kit fa-tf-ai-magic"
        show-icon
        icon-size="5x"
        v-bind="{
          closable: false,
          'data-test': showSuccessfulSummaryAlert
            ? 'products processed successfully summary alert'
            : 'some products have action needed summary alert',
          'is-rounded': true,
          title: summaryAlertTitle,
          description: summaryAlertText,
          type: showSuccessfulSummaryAlert ? 'success' : 'neutral',
        }"
      />
      <section>
        <h1 data-test="product table header">
          {{ tableHeader }}
        </h1>
        <ProductTable
          class="product-table"
        />
        <div
          v-if="!noTableActionAvailable"
          class="button-container"
        >
          <ElDropdown
            v-if="showSplitButton"
            split-button
            size="small"
            type="primary"
            trigger="click"
            data-test="split button"
            @command="handleCommand"
            @click="submitRenewalOrProposal"
          >
            {{ buttonOptions.submitActionText }}
            <ElDropdownMenu slot="dropdown">
              <ElDropdownItem
                data-test="decline products primary action"
                command="split button"
              >
                Decline Products
              </ElDropdownItem>
            </ElDropdownMenu>
          </ElDropdown>

          <template v-else>
            <AppButton
              v-if="showPrimaryButton"
              type="primary"
              :text="buttonOptions.primaryText"
              :data-test="`${buttonOptions.primaryText.toLowerCase()} primary action`"
              @click="handleSmartRenewalOrProposal"
            />
          </template>
          <AppButton
            v-if="showSecondaryButton"
            :data-test="`${buttonOptions.secondaryText.toLowerCase()} secondary action`"
            :text="buttonOptions.secondaryText"
            type="secondary"
            @click="buttonOptions.secondaryAction"
          />
          <AppButton
            v-if="showFirmQuoteButton"
            :data-test="`firm quote secondary action`"
            :icon="someProductsHaveStatus('Firm', 'Firm Expired', 'Closed', 'Selected') ? 'fa-solid fa-pencil' : ''"
            :text="buttonOptions.firmButtonText"
            type="secondary"
            @click="firmButtonClicked"
          />
        </div>
        <FirmQuoteDateModal
          v-if="showFirmQuoteModal"
          :visible.sync="showFirmQuoteModal"
          :products="stopLossProducts"
          data-test="firm quote modal"
        />
        <SelectDeclineModal
          v-if="showSelectDeclineModal"
          :products="declinableProducts"
          :visible.sync="showSelectDeclineModal"
          data-test="select decline products modal"
          @closeDialog="handleSelectDeclineClose"
        />
        <DeclineToQuoteModal
          v-if="showDeclineDialog"
          :visible.sync="showDeclineDialog"
          :products-to-decline="productsToDecline"
          data-test="decline all products confirmation"
        />
        <StartOrUpdateRenewalModal
          v-if="isStartOrUpdateRenewalModalOpen"
          :all-products-not-started="allProductsHaveStatus('Not Started')"
          :document-id="proposalDocument.id"
          :products="products"
          :visible.sync="isStartOrUpdateRenewalModalOpen"
          data-test="start or update renewal modal"
          @closeDialog="closeStartOrUpdateRenewalModal"
          @submitDocument="showThreeFlowAssistModal = $event"
        />
        <ThreeFlowAssistModal
          v-if="showThreeFlowAssistModal"
          :visible.sync="showThreeFlowAssistModal"
          :proposal-document-id="proposalDocument.id"
          @closeDialog="showThreeFlowAssistModal = false"
          @showProductTableInfoMessage="showProductTableInfoMessage"
        />
        <ThreeFlowAssistAndDiveUnsupportedModal
          v-if="showThreeFlowAssistAndDiveUnsupportedModal"
          :visible.sync="showThreeFlowAssistAndDiveUnsupportedModal"
          :tf-assist-and-dive-unsupported-product-types="tfAssistAndDiveUnsupportedProductTypes"
          @closeDialog="showThreeFlowAssistAndDiveUnsupportedModal = false"
        />
        <TermsModal
          v-if="showTermsModal"
          :visible.sync="showTermsModal"
          data-test="terms modal"
          @closeDialog="showTermsModal = false"
        />
        <AppModalNotify
          :visible="showNotifyModal"
          append-to-body
          center-text
          confirm-icon=""
          data-test="confirm failure notification"
          type="danger"
          @confirm="closeNotifyModal"
        >
          <template #title>
            Please try again
          </template>
          <template #ctaConfirmContent>
            Done
          </template>
          <p>
            ThreeFlow encountered an error processing the documents.
            Re-upload your document to try again.
            <br>
            If the problem persists,
            <a
              href="mailto:support@threeflow.com"
              @click="closeNotifyModal"
            >
              contact support
            </a>.
          </p>
        </AppModalNotify>
      </section>
    </div>
    <p>
      By submitting, you agree to our
      <AppButton
        data-test="open terms modal"
        size="text"
        text="terms."
        @click="showTermsModal = !showTermsModal"
      />
    </p>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapWritableState,
  } from 'pinia';
  import { useProductStore } from '@/stores/product.js';
  import { useProductTableStore } from '@/stores/productTable.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';

  import { trackSegmentEvent, isStopLossProduct } from '@watchtowerbenefits/es-utils-public';

  import ProductTable from '@/components/Product/ProductTable.vue';
  import StartOrUpdateRenewalModal from '@/components/Modals/StartOrUpdateRenewalModal/index.vue';
  import DeclineToQuoteModal from '@/components/Modals/DeclineToQuoteModal.vue';
  import SelectDeclineModal from '@/components/Modals/SelectDeclineModal.vue';
  import TermsModal from '@/components/Modals/TermsModal.vue';
  import FirmQuoteDateModal from '@/components/Modals/FirmQuoteDateModal.vue';
  import ThreeFlowAssistModal from '@/components/Modals/StartOrUpdateRenewalModal/ThreeFlowAssist.vue';
  import ThreeFlowAssistAndDiveUnsupportedModal from '@/components/Modals/ThreeFlowAssistAndDiveUnsupportedModal.vue';

  import { formatArrayIntoListOfStrings } from '@/utils/general.js';
  import { firmQuoteCheck } from '@/utils/product.js';
  /**
   * rff:uploadRenewalUploadEnhancements - Upon removal of this feature flag, this component and it's test can be renamed "YourQuotes"
   * and routed to directly instead of being rendered by the current "YourQuotes" parent wrapper component.
   *
   * @exports RfpOverview/YourQuotesWithProductTable
   */

  export default {
    name: 'YourQuotesWithProductTable',
    components: {
      DeclineToQuoteModal,
      ProductTable,
      StartOrUpdateRenewalModal,
      SelectDeclineModal,
      TermsModal,
      FirmQuoteDateModal,
      ThreeFlowAssistModal,
      ThreeFlowAssistAndDiveUnsupportedModal,
    },
    data: () => ({
      showDeclineDialog: false,
      isStartOrUpdateRenewalModalOpen: false,
      showSelectDeclineModal: false,
      showTermsModal: false,
      showFirmQuoteModal: false,
      showThreeFlowAssistModal: false,
      showNotifyModal: false,
      showThreeFlowAssistAndDiveUnsupportedModal: false,
      infoMessage: null,
      errorMessage: null,
      declinableStates: [
        'Not Started',
        'Not Submitted',
        'Ready to Submit',
        'Action Needed',
        'ThreeFlow Assist',
      ],
    }),
    computed: {
      ...mapState(useProjectStore, [
        'proposalDocument',
        'waitForDocumentsToProcess',
      ]),
      ...mapState(usePlanDesignStore, ['validPlanDesign']),
      ...mapState(useCarrierInfoStore, { carrierId: 'id' }),
      ...mapState(useProductStore, [
        'allThreeflowAssistProducts',
        'diveUnsupportedProductTypes',
        'isProcessingDocuments',
        'isSmartProposal',
        'isAllNewCoverage',
        'isMixOfNewAndRenewingCoverage',
        'products',
        'productsToDecline',
        'smartDocType',
        'threeflowAssistSupported',
      ]),
      ...mapWritableState(useProjectStore, [
        'processingDocumentsNotification',
        'showSummaryAlert',
        'proposalDocumentId',
      ]),
      ...mapWritableState(useProductTableStore, ['productTableData']),
      ...mapWritableState(useRateEntryStore, ['validRateEntry']),
      /**
       * Generates an array of product type names in the project that are Tf Assist and DIVE unsupported and also in a not started state.
       *
       * @returns {Array}
       */
      tfAssistAndDiveUnsupportedProductTypes() {
        const unsupportedProductTypes = this.productTableData.reduce(
          (acc, { isTfAssistAndDiveUnsupportedProduct, status: { status }, productTypeName }) => {
            if (isTfAssistAndDiveUnsupportedProduct && status === 'Not Started') {
              acc.push(productTypeName);
            }

            return acc;
          },
          [],
        );

        return [...new Set(unsupportedProductTypes)];
      },
      /**
       * Array of products in declinable states.
       *
       * @returns {Array}
       */
      declinableProducts() {
        const declinableTableProductIds = this.productTableData
          .filter(({ status }) => this.declinableStates.includes(status.status))
          .map(({ id }) => id);

        return this.products.filter(({ id }) => declinableTableProductIds.includes(id));
      },

      /**
       * Array of Stop Loss products
       *
       * @returns {Array}
       */
      stopLossProducts() {
        return this.products.filter((product) => isStopLossProduct(product));
      },

      /**
       * Distill the statuses for the products in the table down into a single array of statuses.
       *
       * @returns {Array}
       */
      productStatuses() {
        return this.productTableData.map(({ status }) => status.status);
      },
      /**
       * Determine whether to show the successful summary alert based on product statuses.
       *
       * @returns {boolean}
       */
      showSuccessfulSummaryAlert() {
        return this.showSummaryAlert
          && this.someProductsHaveStatus('Ready to Submit')
          && !this.someProductsHaveStatus('Action Needed');
      },
      /**
       * Determine whether to show the action needed summary alert based on product statuses.
       *
       * @returns {boolean}
       */
      showActionNeededSummaryAlert() {
        return this.showSummaryAlert
          && this.someProductsHaveStatus('Action Needed');
      },

      /**
       * Text for the summary alert (blurbiage)
       *
       * @returns {string}
       */
      summaryAlertText() {
        if (this.showSummaryAlert === 'update') {
          return this.showSuccessfulSummaryAlert
            ? `Your ${this.smartDocType()}s have been successfully updated.`
            : 'Some rate information may be missing or require your review. See the products flagged below.';
        }

        const quoteOrRenewalSuccessMessage = this.isSmartProposal
          ? 'ThreeFlow found and entered your rates. You are encouraged to review your quote before submission. Quotes that are ready to submit will be auto-submitted in 24 hours.'
          : 'ThreeFlow found and entered your rates. You are encouraged to review your quote before submission by selecting review details. Quotes that are ready to submit will be auto-submitted in 24 hours.';

        return this.showSuccessfulSummaryAlert
          ? quoteOrRenewalSuccessMessage
          : 'Some rate information may be missing or require your review. See the products flagged below. Quotes that don\'t require further action will be auto-submitted in 24 business hours.';
      },
      /**
       * Title text for the summary alert (blurb title)
       *
       * @returns {string}
       */
      summaryAlertTitle() {
        if (this.showSummaryAlert === 'update') {
          return this.showSuccessfulSummaryAlert
            ? `Your ${this.smartDocType()} update is complete`
            : 'Your update requires action';
        }

        return this.showSuccessfulSummaryAlert
          ? `Your ${this.smartDocType()} is ready to review and submit`
          : `Your ${this.smartDocType()}s require action`;
      },
      /**
       * Decides the correct renewal and decline button text based on product states.
       * Primary button:
       * - At least one product in Not Started: 'Start Renewal'
       * - NO products in Not Started: 'Update Renewal'
       *
       * Firm quote button text:
       * - Any products have a stop_loss_state of firm_quote_requested
       *
       * @returns {string}
       */
      buttonOptions() {
        const firmQuote = this.products.find(
          ({ firm_quote_expires_on: exp }) => exp,
        );
        const firmQuoteDate = firmQuote ? new Date(firmQuote.firm_quote_expires_on) : null;
        const quoteOrRenewal = this.smartDocType(true);
        const updateOrStart = this.allProductsHaveStatus('not started') ? 'Start' : 'Update';

        return {
          primaryText: this.threeflowAssistSupported ? 'Send to ThreeFlow Assist' : `${updateOrStart} ${quoteOrRenewal}`,
          secondaryText: this.someProductsHaveStatus('Ready to Submit') ? `Update ${quoteOrRenewal}` : 'Decline Products',
          secondaryAction: this.someProductsHaveStatus('Ready to Submit') ? this.handleSmartRenewalOrProposal : this.onDeclineProductsClick,
          firmButtonText: firmQuote
            ? `Firm Quote: ${firmQuoteDate.getMonth() + 1}/${firmQuoteDate.getDate()}/${firmQuoteDate.getFullYear()}`
            : 'Set Firm Quote',
          submitActionText: `Submit ${quoteOrRenewal}`,
        };
      },
      /**
       * Show the primary button if:
       * - NO products are Processing
       *   AND NO products are Ready to Submit
       *   AND ALL products are not in ThreeFlow Assist
       *   AND ALL products are not tfAssistAndDiveUnsupportedProductTypes
       * If the button is shown and EVERY product is Not Started, the button will show Start Renewal/Proposal, otherwise it will show Update Renewal/Proposal
       *
       * @returns {boolean}
       */
      showPrimaryButton() {
        const allProductsAreTfAssistAndDiveUnsupported = this.productTableData.every(
          ({ isTfAssistAndDiveUnsupportedProduct }) => isTfAssistAndDiveUnsupportedProduct,
        );

        return !this.someProductsHaveStatus('processing')
          && !this.someProductsHaveStatus('ready to submit')
          && !this.allProductsHaveStatus('threeflow assist')
          && !allProductsAreTfAssistAndDiveUnsupported
          && !(this.allThreeflowAssistProducts && this.allProductsHaveStatus('Action Needed'));
      },
      /**
       * Show the secondary button if:
       * - EVERY product is NOT in Processing, Submitted, Firm Requested, Firm, Firm Expired, Declined, Selected, or Closed
       *   AND ANY products are in a Declinable state
       *   AND NOT all products are stop loss or critical illness products in Ready to Submit state
       * If ANY products are Ready to Submit, the button will read Update Renewal, otherwise it will show Decline Products
       *
       * @returns {boolean}
       */
      showSecondaryButton() {
        return (!this.allProductsHaveStatus('Processing', 'Submitted', 'Firm Requested', 'Firm', 'Firm Expired', 'Declined', 'Selected', 'Closed')
          && this.someProductsHaveStatus(...this.declinableStates))
          && !(this.allThreeflowAssistProducts && this.allProductsHaveStatus('Ready to Submit'));
      },
      /**
       * Show the split button if:
       * - ANY product is Ready to Submit
       *
       * @returns {boolean}
       */
      showSplitButton() {
        return this.someProductsHaveStatus('Ready to Submit')
          || (this.allThreeflowAssistProducts && this.allProductsHaveStatus('Ready to Submit'));
      },
      /**
       * Show Firm Quote button if any products are stop loss
       *
       * @returns {boolean}
       */
      showFirmQuoteButton() {
        const stopLossProducts = this.products.filter(
          (product) => isStopLossProduct(product),
        );

        return stopLossProducts.length && stopLossProducts
          .some((product) => firmQuoteCheck(product.stop_loss_state));
      },
      /**
       * Maps over the productTableData array and returns if any actions
       * are available based on product state
       *
       * @returns {boolean}
       */
      noTableActionAvailable() {
        const noActionStates = [
          'Processing',
          'Declined',
        ];
        const productsCompletedButNoFirmQuoteRequested = (this.allThreeflowAssistProducts
          && this.products.every((product) => product.state === 'completed' && !firmQuoteCheck(product.stop_loss_state)));

        return this.allProductsHaveStatus(...noActionStates) || productsCompletedButNoFirmQuoteRequested;
      },
      /**
       * Displays table header based on table type and product status
       *
       * @returns {string}
       */
      tableHeader() {
        if (this.isSmartProposal) {
          if (this.isAllNewCoverage) return 'New Coverage';
          if (this.isMixOfNewAndRenewingCoverage) return 'New & Renewing Coverage';
        }

        return 'Renewing Coverage';
      },
    },
    watch: {
      isProcessingDocuments: {
        async handler(newVal, oldVal) {
          if (newVal) {
            this.processingDocumentsNotification = this.$notify({
              message: 'Your documents are being processed<br>and will be ready in 1-5 minutes.',
              dangerouslyUseHTMLString: true,
              position: 'bottom-right',
              iconClass: 'fa-solid fa-circle-notch fa-spin',
              customClass: 'snackbar',
              showClose: false,
              duration: 0,
            });
          } else {
            this.processingDocumentsNotification?.close();
            try {
              this.processingDocumentsNotification?.destroyElement();
            } catch {
              // do nothing, this is just in case the element doesn't get destroyed because the user navigated too quickly
            }
            const doneProcessing = oldVal === true && newVal === false;
            const unreadNotificationCount = await this.getUnreadDiveFailureNotificationsCount();

            this.showNotifyModal = unreadNotificationCount > 0;

            if (doneProcessing && this.proposalDocument?.id && this.isSmartProposal) {
              this.getPlanDesignCompleteness(this.proposalDocument.id);
            }
          }
        },
        immediate: true,
      },
    },
    async mounted() {
      if (this.$route.query.fromEmail) {
        this.showSummaryAlert = true;
      }
      if (this.threeflowAssistSupported && !!this.diveUnsupportedProductTypes.length) {
        const message = `${formatArrayIntoListOfStrings(this.diveUnsupportedProductTypes)} supported by ThreeFlow Assist. ThreeFlow will enter your quote within two business days and send it to you to review and submit.`;

        this.showProductTableInfoMessage(message);
      }

      if (this.isProcessingDocuments) {
        this.checkForProcessingDocuments(this.proposalDocumentId);
        this.pollProposalDocumentProcessingState(this.proposalDocumentId);
      }
    },
    created() {
      const containsNotStartedtfAssistAndDiveUnsupportedProducts = this.productTableData.some(
        ({ isTfAssistAndDiveUnsupportedProduct, status: { status } }) => isTfAssistAndDiveUnsupportedProduct && status === 'Not Started',
      );

      if (containsNotStartedtfAssistAndDiveUnsupportedProducts) {
        this.showThreeFlowAssistAndDiveUnsupportedModal = true;
      }
    },
    beforeDestroy() {
      this.showSummaryAlert = false;
      if (this.waitForDocumentsToProcess) this.clearDocumentsInterval();

      this.processingDocumentsNotification?.close();
      this.$message.closeAll();

      try {
        this.processingDocumentsNotification?.destroyElement();
      } catch {
        // No need to do anything if this fails
        // destroyElement is only called in case the navigation has
        // interrupted $notify's transitionend event which hides the element
      }
      try {
        this.errorMessage?.destroyElement();
      } catch {
        // No need to do anything if this fails
        // destroyElement is only called in case the navigation has
        // interrupted $message's transitionend event which hides the element
      }
      try {
        this.infoMessage?.destroyElement();
      } catch {
        // No need to do anything if this fails
        // destroyElement is only called in case the navigation has
        // interrupted $message's transitionend event which hides the element
      }
    },
    methods: {
      ...mapActions(useProductStore, [
        'validateProduct',
        'submitQuote',
      ]),
      ...mapActions(useProductTableStore, ['getPlanDesignCompleteness']),
      ...mapActions(useProjectStore, [
        'getUnreadDiveFailureNotificationsCount',
        'markUnreadDiveFailureNotificationsAsRead',
        'pollProposalDocumentProcessingState',
        'clearDocumentsInterval',
        'checkForProcessingDocuments',
      ]),

      /**
       * Show an info message in the product table.
       *
       * @param {string} message
       */
      showProductTableInfoMessage(message) {
        this.infoMessage = this.$message({
          showClose: true,
          duration: 0,
          message,
          customClass: 'el-message--info',
          iconClass: 'el-message__icon fa-regular fa-clock tf-blue-icon',
          type: 'info',
        });
      },
      /**
       * Handle split-button dropdown command.
       *
       * @param {string} command
       */
      handleCommand(command) {
        this.onDeclineProductsClick({}, command);
      },
      /**
       * Close DIVE failure notification modal and hit API to indicate modal has been seen
       */
      async closeNotifyModal() {
        await this.markUnreadDiveFailureNotificationsAsRead();
        this.showNotifyModal = false;
      },
      /**
       * Closes the start or update renewal modal and sends an analytics call.
       *
       * @param {number} formDisplayedUponClose - which form was displaying before the user closed the modal
       */
      closeStartOrUpdateRenewalModal(formDisplayedUponClose) {
        this.isStartOrUpdateRenewalModalOpen = !this.isStartOrUpdateRenewalModalOpen;

        trackSegmentEvent(`Start or update renewal/quote modal abandoned on the ${formDisplayedUponClose} form`, {
          product_ids: this.products.map(({ id }) => (id)),
        });
      },
      /**
       * Handle click on 'Decline' products button
       *
       * @param {object} event
       * @param {string} buttonType
       */
      onDeclineProductsClick(event, buttonType = 'primary') {
        this.showSelectDeclineModal = !this.showSelectDeclineModal;
        trackSegmentEvent(`Smart ${this.smartDocType(true)} Decline Products (${buttonType}) clicked`, { project_id: this.$route.params.projectId });
      },
      /**
       * Submits a renewal or proposal.
       *
       */
      async submitRenewalOrProposal() {
        const showValidateOrSubmitError = (message = 'Your request did not go through. Please check the values on the Rate Entry page for any errors.') => {
          this.errorMessage = this.$message({
            showClose: true,
            message,
            type: 'error',
          });
        };
        const readyToSubmitProductIds = this.productTableData.reduce((acc, { id, status: { status } }) => {
          if (status === 'Ready to Submit') {
            acc.push(id);
          }

          return acc;
        }, []);

        trackSegmentEvent(`Submit Smart ${this.smartDocType(true)} from below table clicked`, {
          product_ids: readyToSubmitProductIds,
        });
        try {
          await Promise.all(readyToSubmitProductIds.map(async (id) => {
            await this.validateProduct(id);
          }));
        } catch {
          showValidateOrSubmitError();

          return;
        }

        try {
          await Promise.all(readyToSubmitProductIds.map(async (id) => {
            await this.submitQuote(id);
          }));
        } catch {
          showValidateOrSubmitError('Not all products were successfully submitted for renewal. Please try again.');
        }
      },
      /**
       * Handles opening the start or update renewal modal.
       *
       */
      handleSmartRenewalOrProposal() {
        this.isStartOrUpdateRenewalModalOpen = true;
      },
      /**
       * Check if every one of the product states are in the specified status.
       *
       * @param {...string} statuses
       * @returns {boolean}
       */
      allProductsHaveStatus(...statuses) {
        return this.productStatuses.every(
          (productState) => statuses.some((status) => new RegExp(`^${status}$`, 'i').test(productState)),
        );
      },
      /**
       * Check if any of the product states are in the specified status.
       *
       * @param {...string} statuses
       * @returns {boolean}
       */
      someProductsHaveStatus(...statuses) {
        return this.productStatuses.some(
          (productState) => statuses.some((status) => new RegExp(`^${status}$`, 'i').test(productState)),
        );
      },
      /**
       * Opens DeclineToQuoteModal if there are products to decline and closes selectDeclineModal.
       *
       */
      handleSelectDeclineClose() {
        this.showSelectDeclineModal = false;
        if (this.productsToDecline.length > 0) this.showDeclineDialog = !this.showDeclineDialog;
      },
      /**
       * Handle clicks of the Firm Quote button. Show the firm quote modal
       */
      firmButtonClicked() {
        this.showFirmQuoteModal = true;
      },
    },
  };
</script>

<style scoped lang="scss">
.button-container {
  margin-bottom: 30px;
}

.summary-alert.app-alert {
  width: clamp(375px, 760px, 760px);
  margin-bottom: 20px;

  :deep(.title) {
    text-transform: uppercase;
  }
}

:deep(.el-dropdown) {
  margin-right: var(--tf-btn-margin-medium);

  .el-button {
    background: var(--tf-blue);
    font-size: 16px;
    font-weight: 600;
    border: none;
  }
}

.your-quotes-content-grid {
  display: grid;
  place-content: center;
  align-content: space-between;
  height: calc(100vh - 350px);
}

:deep(.el-dropdown-menu__item) {
  font-size: 16px;
  color: var(--tf-blue);

  &:hover {
    color: var(--tf-blue);
  }
}
</style>

<style lang="scss">
  i.tf-blue-icon {
    color: var(--tf-blue);
  }

  .snackbar {
    background: var(--tf-gray-dark);
    width: auto;
    padding: 10px 16px;

    .el-notification__group.is-with-icon {
      margin-left: 10px;
    }

    .el-notification__content {
      margin: 2px 0 0;
      color: $tf-white;
    }

    .fa-spin {
      animation-name: fa-spin;
    }

    i {
      color: var(--tf-green);
      height: 40px;
      width: 40px;
      font-size: 40px;
    }

    .intercom-loaded & {
      margin-right: 70px;
    }
  }
</style>
