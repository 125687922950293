import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { useProductStore } from '@/stores/product.js';
import rateEntryService from '@/services/rateEntry.js';
import planDesignService from '@/services/planDesign.js';
import productService from '@/services/product.js';

const constructRateAttributes = (rateEntryContainers, projectProduct) => {
  const rateAttributes = {};

  rateEntryContainers.forEach(({ id: containerId, rateAttributes: rateAttrs }) => {
    let proposalValues;
    let policyValues;

    // Process each rate attribute
    rateAttrs.forEach(({ id: attributeId, products }) => {
      // Find the policy and proposal documents from which we can pull tier group information
      policyValues = products.find(({ document_type: docType }) => docType.toLowerCase() === 'policy');
      proposalValues = products.find(({ document_type: docType }) => docType.toLowerCase() !== 'policy');

      const attributeType = proposalValues.tier_group?.name;
      let policyAttributeType;
      let rateValue;
      const rateValues = {};
      let value;
      const isPlan = projectProduct.container_type.toLowerCase() === 'plan';

      // Plan based products have a rate_value key
      // Class based products have a rate_values key (plural)
      if (isPlan) {
        policyAttributeType = policyValues.tier_group.name
          || policyValues.tier_group.tier_subtypes[0].rate_value.type;
        rateValue = proposalValues.tier_group?.tier_subtypes[0].rate_value;
      } else {
        policyAttributeType = policyValues.tier_group.name
          || policyValues.tier_group.tier_subtypes[0].rate_values[0].type;
        rateValue = proposalValues.tier_group?.tier_subtypes[0].rate_values[0] || null;
      }

      // If the proposal has no tier group yet
      if (!proposalValues.tier_group) {
        // If the policy also has no tier group (unlikely) set it to Composite
        // This should never be the case as a policy must have a tier group
        if (!policyValues.tier_group) {
          rateValues[policyAttributeType] = {
            type: policyAttributeType,
            values: [{
              comparison_flag: 'deviation_detected',
              display_label: 'Composite',
              label: 'composite',
              value: null,
              volume: null,
            }],
          };
        } else {
          // Propsal has no tier group so we have to get the tier group information from the policy
          const {
            tier_subtypes: subtypes,
          } = policyValues.tier_group;

          subtypes.forEach((subtype) => {
            value = isPlan ? subtype.rate_value : subtype.rate_values[0];

            // If we're in this state, we don't want to copy the actual values for each rateValue
            // So exclude the value key from the constructed values objects
            if (!subtype.id) {
              rateValues[value.type] = {
                type: value.type,
                values: value.values.map(({ value: unused, ...rest }) => ({ ...rest })),
              };
            } else {
              rateValues[subtype.id] = {
                type: value.type,
                values: value.values.map(({ value: unused, ...rest }) => ({ ...rest })),
              };
            }
          });
        }
      } else {
        // This is the case we should run into most often, where we just get the values from the proposal
        proposalValues.tier_group.tier_subtypes.forEach((subtype) => {
          value = isPlan ? subtype.rate_value : subtype.rate_values[0];
          if (!subtype.id) {
            rateValues[value.type] = {
              type: value.type,
              values: value.values,
            };
          } else {
            rateValues[subtype.id] = {
              type: value.type,
              values: value.values,
            };
          }
        });
      }

      const newAttribute = {
        attributeId,
        attributeType: attributeType || rateValue?.type,
        containerIds: containerId,
        originalTierGroupId: proposalValues.tier_group?.id,
        policyAttributeType,
        rateBasis: rateValue?.rate_basis,
        rateValues,
        tierGroupId: proposalValues.tier_group?.id || null,
      };
      const attrId = Array.isArray(containerId) ? `${containerId.join('_')}` : containerId;

      rateAttributes[`${attrId}_${attributeId}`] = newAttribute;
    });
  });

  return rateAttributes;
};

export const useRateEntryStore = defineStore('rateEntry', {
  state: () => ({
    // Store Data
    loadingRateEntry: false,
    rateErrors: [],
    info: {},
    isLoaded: false,
    rateGuarantee: null,
    patchingGuarantee: false,
    editingRateAttributeValue: false,
    rateEntryContainers: [],
    validRateEntry: false,
    classesAreSeparated: false,
    separatedClassIds: [],
    rateUpdateRequests: [],
    drainingUpdateRequests: false,
  }),
  getters: {
    /**
     * Check that all rate values are a valid, positive number
     *
     * @param {object} state
     * @returns {object}
     */
    isRateEntryValid: (state) => Object.keys(state.rateAttributes || {})
      .every((attributeId) => Object.keys(state.rateAttributes[attributeId].rateValues)
        .every((subtypeId) => state.rateAttributes[attributeId].rateValues[subtypeId].values
          .every((value) => !Number.isNaN(parseFloat(value.value)) || parseFloat(value.value) >= 0)))
      && (useProductStore().isStopLoss || (!useProductStore().isStopLoss && !!state.rateGuarantee?.value)),
    /**
     * Rate attributes constructs the rate entry table(s) and form fields
     *
     * @param {object} state
     * @returns {object}
     */
    rateAttributes: (state) => constructRateAttributes(state.rateEntryContainers, state.info),
  },
  actions: {
    constructRateAttributes,
    /**
     * Fires the next request in the rate update queue
     *
     * @param {object} data
     */
    async drainUpdateRequests(data) {
      // If the queue has items and we're not currently processing one
      if (!this.drainingUpdateRequests && this.rateUpdateRequests.length) {
        this.drainingUpdateRequests = true;
        try {
          // result is the updated rate attribute data
          const request = this.rateUpdateRequests.shift();
          const result = await request(data);

          this.drainingUpdateRequests = false;

          if (result) {
            this.drainUpdateRequests(result);
          } else {
            // Recurse if the request is still processing
            setTimeout(() => this.drainUpdateRequests(data), 100);
          }
        } catch (x) {
          this.drainingUpdateRequests = false;
        }
      } else if (this.drainingUpdateRequests) {
        // Done with all update requests
        if (!this.rateUpdateRequests.length) {
          this.drainingUpdateRequests = false;
        } else {
          // We're still waiting on a request. Recurse.
          setTimeout(() => this.drainUpdateRequests(data), 100);
        }
      }
    },
    // Store Actions
    /**
     * Unroll a rolled up rate entry container and create separate containers for them.
     *
     * @param {Array} rolledContainerIds - The array of container ids inside of the rolled up container.
     * @param {Array} containers - the array of rate entry containers.
     * @param {Array} containerInfos - An array of rate entry info objects grouped by container.
     */
    unrollContainer(rolledContainerIds, containers, containerInfos) {
      const rolledContainerIndex = containers.findIndex(
        ({ id }) => id.join('') === rolledContainerIds.join(''),
      );
      const containerToUnroll = cloneDeep(containers.splice(rolledContainerIndex, 1)[0]);

      containerToUnroll.id.forEach((id) => {
        const clonedRateAttributes = cloneDeep(containerToUnroll.rateAttributes);
        const containerIndex = containers.findIndex(({ id: rolledIds }) => rolledIds.join('') === id.toString());

        if (containerIndex === -1) {
          const containerInfo = containerInfos.find(({ id: infoId }) => infoId === id);

          containers.push({
            containerNames: [containerInfo.name],
            rateAttributes: clonedRateAttributes,
            description: [containerInfo.description],
            id: [id],
            name: `Class ${containerInfo.name}`,
          });
        } else {
          clonedRateAttributes.forEach((attribute) => {
            containers[containerIndex].rateAttributes.push(cloneDeep(attribute));
          });
        }
      });
    },
    /**
     * Parse rate entry containers for Class type containers. Unroll a rolled container if
     * a rolled container's array of ids is provided.
     *
     * @param {object} rateEntry - the rate entry response to parse containers from.
     * @param {Array} rolledContainerIds - (optional) The array of container ids inside of a rolled up container.
     * @returns {Array}
     */
    setClassTypeContainers(rateEntry, rolledContainerIds = []) {
      const containers = rateEntry.groupings.map((container) => ({
        containerNames: container.container_names, // used for sorting in case there is a roll out later
        description: container.container_ids.map((id) => rateEntry.container_info
          .find((containerInfo) => containerInfo.id === id).description),
        id: container.container_ids,
        name: container.label,
        rateAttributes: container.rate_attributes,
      }));

      if (rolledContainerIds.length) {
        this.unrollContainer(rolledContainerIds, containers, rateEntry.container_info);
      }

      containers.sort((a, b) => {
        let order = 0;
        const nameA = Number(a.containerNames[0]);
        const nameB = Number(b.containerNames[0]);

        if (nameA < nameB) {
          order = -1;
        } else if (nameA > nameB) {
          order = 1;
        }

        return order;
      });

      return containers;
    },

    /**
     * Parse rate entry containers for Plan type containers.
     *
     * @param {object} rateEntry - the rate entry response to parse containers from.
     * @returns {Array}
     */
    setPlanTypeContainers(rateEntry) {
      const containers = rateEntry.containers.map((container) => {
        const containerInfo = rateEntry.container_info
          .find((containerClass) => containerClass.id === container.id);
        const selectedTierGroup = container.available_plan_design_tier_groups
          .find((tierGroup) => tierGroup.selected);
        const type = containerInfo.container_type_name;

        // For plan-based products, we're using the container.description as the "name" and the container.container_type_name as the "description".
        return {
          description: [type], // see above description
          id: [container.id],
          name: containerInfo.description || type, // see above description
          rateAttributes: container.rate_attributes,
          tierGroups: container.available_plan_design_tier_groups,
          // ATM dental is the only Plan Based product that will come back with a selected tier group 100% of the time
          tierGroupId: selectedTierGroup ? selectedTierGroup.id : null,
          type: type.toLowerCase(),
        };
      });

      return containers;
    },

    /**
     * Sets the rate entry containers, info, and rate guarantee properties for the store and
     * sets the loadingRateEntry property to false once finished.
     *
     * @param {object} rateEntry
     * @param {Array} rolledContainerIds
     * @returns {object}
     */
    setRateEntry(rateEntry, rolledContainerIds) {
      this.info = rateEntry;
      this.rateGuarantee = rateEntry.rate_guarantee;

      if (rateEntry.container_type === 'Class') {
        const ids = this.classesAreSeparated ? this.separatedClassIds : rolledContainerIds;

        this.rateEntryContainers = this.setClassTypeContainers(rateEntry, ids);
      } else {
        this.rateEntryContainers = this.setPlanTypeContainers(rateEntry);
      }

      this.loadingRateEntry = false;

      return this.rateAttributes;
    },

    /**
     * Copy all the rate values from the incumbent product to the product being edited.
     *
     * @param {number} productId
     * @param {Array<number>} containerIds
     * @param {string} productState
     */
    async copyInforceRate(productId, containerIds, productState) {
      this.loadingRateEntry = true;
      this.rateEntryContainers = [];

      if (productId) {
        try {
          const endpointState = productState === 'completed' || productState === 'pending_review' ? 'edit' : 'start';

          await useProductStore().updateProductState({ endpointState, productId });
        } catch {
          throw new Error('An error occurred when attempting to update the product state.');
        }
      }

      try {
        const {
          project_product: rateEntry,
        } = await rateEntryService.copyRatesFromInforce(productId, containerIds);

        this.setRateEntry(rateEntry);
        this.rateErrors.splice(0, this.rateErrors.length);
        this.loadingRateEntry = false;
      } catch {
        this.loadingRateEntry = false;
        throw new Error('An error occurred when attempting to copy rates from inforce.');
      }

      // Because triggering updates to the plan design updates the product state, for now we have to manually refetch the
      // product so we can get the updated product state.
      // This will be fixed with https://watchtower.atlassian.net/browse/LC-1724
      if (productId) {
        const { product } = await productService.getProduct(productId);

        useProductStore().updateProduct(product);
        useProductStore().currentProduct = product;
      }
    },

    /**
     * Get rate entry from API
     *
     * @param {object} root
     * @param {number|null} root.productId
     * @param {Array} root.rollOutIds
     * @returns {Promise}
     */
    async getRateEntry({ productId = useProductStore().productId, rollOutIds = [] }) {
      this.loadingRateEntry = true;

      const { project_product: rateEntry } = await rateEntryService.getRateEntry(productId);
      const rateAttributes = this.setRateEntry(rateEntry, rollOutIds);

      this.isLoaded = true;
      this.loadingRateEntry = false;

      return rateAttributes;
    },

    /**
     * Resets the rate entry info object and rate entry containers array.
     */
    clearRateEntry() {
      this.info = {};
      this.rateEntryContainers = [];
    },

    /**
     * Delete existing rate values
     * Called when changing tier group or subtype
     *
     * @param {string} payload
     */
    async deleteAttribute(payload) {
      try {
        await rateEntryService.deleteRateAttributeValues(payload);
      } catch (error) {
        throw new Error(`An error occurred when attempting to delete rate attribute values. Error: ${error.message}`);
      }
    },

    /**
     * Update tier group / rate options
     *
     * @param {string} payload
     */
    async updateTierGroup(payload) {
      try {
        const { project_product: rateEntry } = await rateEntryService.patchTierGroup(payload);

        this.setRateEntry(rateEntry);
      } catch (error) {
        throw new Error(`An error occurred when attempting to update rate options. Error: ${error.message}`);
      }
    },

    /**
     * Updates rate fields in the Rate Entry tab, and sends the updated values to the API.
     *
     * @param {object} params
     * @param {string} params.productId
     * @param {string} params.productState
     * @param {string} params.storeAttributeId
     * @param {string} params.subtypeId
     * @param {string} params.rateBasis
     * @param {Array} params.rateValues
     * @param {number} params.tierGroupId
     * @param {object} rateAttributes
     * @returns {Promise<undefined|Function>} Returns a promise that resolves to a function if the tier group ID has not changed or undefined if it has.
     */
    async updateRateAttribute({
      productId,
      productState,
      storeAttributeId,
      tierGroupId,
      subtypeId,
      rateBasis,
      rateValues,
    }, rateAttributes) {
      const rateAttribute = rateAttributes || this.rateAttributes[storeAttributeId];
      const rateVals = rateValues || rateAttribute.rateValues;
      let updatedProduct;

      // Test for null values
      if (!subtypeId || !rateAttribute || !productId) {
        return undefined;
      }

      const patchRateAttribute = async (id) => {
        // We need to patch product states.

        if (productState === 'completed' || productState === 'pending_review') {
          await useProductStore().updateProductState({ endpointState: 'edit', productId });
        } else if (productState === 'not_started') {
          await useProductStore().updateProductState({ endpointState: 'start', productId });
        }

        const { project_product: updatedRates } = await rateEntryService.patchRateAttribute({
          productId,
          rateAttribute: {
            ...rateAttribute,
            tierGroupId: tierGroupId || rateAttribute.tierGroupId || id,
            rateValues: rateVals,
            rateBasis,
          },
          subtypeId: id,
        });

        // Last update request. Refresh the product
        if (!this.rateUpdateRequests.length) {
          const { product } = await productService.getProduct(productId);

          useProductStore().updateProduct(product);
        }

        return updatedRates;
      };

      try {
        if (['CompositeRateValue', 'AgeBandedRateValue', 'CustomRateValue'].includes(subtypeId)) {
          updatedProduct = await patchRateAttribute(subtypeId);
        } else if (!Number.isNaN(parseInt(subtypeId, 10))) {
          updatedProduct = await patchRateAttribute(parseInt(subtypeId, 10));
        }

        this.editingRateAttributeValue = false;

        this.rateErrors.splice(0, this.rateErrors.length);
      } catch (error) {
        throw new Error(`An error occurred when attempting to patch rate attributes. Error: ${error.message}`);
      }

      let newRateAttributes;
      let newRateEntryContainers;

      if (updatedProduct.container_type === 'Class') {
        newRateEntryContainers = this.setClassTypeContainers(updatedProduct);

        newRateAttributes = this.constructRateAttributes(
          newRateEntryContainers,
          updatedProduct,
        );
      } else {
        newRateEntryContainers = this.setPlanTypeContainers(updatedProduct);

        newRateAttributes = this.constructRateAttributes(
          newRateEntryContainers,
          updatedProduct,
        );
      }

      if (productId === useProductStore().productId) {
        this.rateEntryContainers = newRateEntryContainers;
      }

      return newRateAttributes;
    },

    /**
     * Asynchronously patches the rate guarantee of a product.
     *
     * @param {object} params
     * @param {string} params.productId
     * @param {string} params.productState
     * @returns {undefined} Does not return a value.
     */
    async patchRateGuarantee({ productId, productState }) {
      if (!this.rateGuarantee || !productId) {
        return;
      }

      this.patchingGuarantee = true;

      // Change validPlanDesign to false since Plan Design will need to be validated again.
      if (this.validRateEntry) {
        this.validRateEntry = false;
      }

      if (productId) {
        const endpointState = productState === 'completed' || productState === 'pending_review' ? 'edit' : 'start';

        await useProductStore().updateProductState({ endpointState, productId });
      }

      await planDesignService.patchPlanAttribute({
        plan_design_attribute_id: this.rateGuarantee.plan_design_attribute_id,
        product_id: productId,
        project_products_container_ids: this.info.container_info.map((container) => container.id),
        tier_group_id: this.rateGuarantee.tier_group_id,
        tier_subtype_id: this.rateGuarantee.tier_subtype_id,
        value: this.rateGuarantee.value,
      });

      // Because triggering updates to the plan design updates the product state, for now we have to manually refetch the
      // product so we can get the updated product state.
      // This will be fixed with https://watchtower.atlassian.net/browse/LC-1724
      if (productId) {
        const { product } = await productService.getProduct(productId);

        useProductStore().updateProduct(product);
        useProductStore().currentProduct = product;
      }

      this.patchingGuarantee = false;
    },

    /**
     * Asynchronously patches the tier group of a plan design value.
     *
     * @param {object} params
     * @param {string} params.containerId
     * @param {string} params.productId
     * @param {string} params.tierGroupId
     * @returns {object} The response from the plan design service.
     */
    async patchRateEntryPlanValueTierGroup({ containerId, productId, tierGroupId }) {
      this.loadingRateEntry = true;

      try {
        const response = await planDesignService.patchPlanDesignValueTierGroup({
          containerId: containerId[0],
          productId,
          tierGroupId,
        });

        this.rateEntryContainers.find((container) => container.id === containerId).tierGroupId = tierGroupId;

        this.loadingRateEntry = false;

        return response;
      } catch (error) {
        this.loadingRateEntry = false;
        throw error;
      }
    },

    /**
     * Hits the API to validate the rate structure of a product by its ID.
     *
     * @param {string} productId
     * @returns {boolean|undefined} Returns the validation status of the product's rate structure, or undefined if the product ID is not provided.
     */
    async validateRateEntry(productId) {
      if (!productId) {
        return undefined;
      }

      try {
        const { product } = await rateEntryService.validateRateEntry(productId);

        this.validRateEntry = product.valid_rate_structure;

        useProductStore().updateProduct(product);

        return product.valid_rate_structure;
      } catch (error) {
        if (error.data.product) {
          this.validRateEntry = error.data.product.valid_rate_structure;
        }
        throw error;
      }
    },

    /**
     * Validate the rate guarantee value
     *
     * @param {string} productId
     * @returns {boolean|undefined} validation status
     */
    async validateRateGuarantee(productId) {
      if (!productId) {
        return undefined;
      }

      const { product } = await rateEntryService.validateRateGuarantee(productId);

      useProductStore().updateProduct(product);

      return product.valid_rate_guarantee;
    },
  },
});
